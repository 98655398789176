export const hero = {
  title: "Online ordering anytime, on any device for dine-in or on the go",
  subTitle: "Mobile and online ordering",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/mobile-and-online-ordering/demo",
  },
};

export const heroImage = {
  imageName: "products-online-ordering-hero-new.png",
};

export const secondaryHeroTwoCols = {
  title: "Let your guests order how they want",
  bodyData: [
    {
      title: "Web-based ordering",
      subtext:
        "Our easy-to-use web interface gives guests the option to order food, beverage, or merchandise right from the table or for takeout and delivery simply by scanning a QR code or accessing a link. No mobile app needed.",
    },
    {
      title: "Mobile app and website integration",
      subtext:
        "Leverage our fully documented restful API for a white-labeled solution that can be embedded in your existing mobile app or website.",
    },
  ],
  image: {
    imageName: "products-online-ordering-secondary-hero-2.png",
    mobileImageName: "products-online-ordering-secondary-hero-2-mobile.png",
    title: "We’re dedicated to your success",
    subtext: "MOBILE AND ONLINE ORDERING",
  },
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/mobile-and-online-ordering/demo",
  },
};

export const productOverview = {
  subSections: [
    {
      title: "Easy to set up, easy to use",
      subtext: `Setting up an intuitive, easy-to-use experience for customers is simple. Plus, a clean, mobile-first design ensures your customers can quickly and easily order and pay.`,
      imageName: "products-online-ordering-product-1.png",
      cta: {
        title: "Learn more",
        target: "/enterprise/mobile-and-online-ordering/demo",
      },
    },
    {
      title: "Built-in item customization and upselling",
      subtext: `Increase sales effortlessly with add-on modifiers and automated suggestions for paired items to increase average order size.`,
      imageName: "products-online-ordering-product-2.png",
      cta: {
        title: "Learn more",
        target: "/enterprise/mobile-and-online-ordering/demo",
      },
    },
    {
      title: "Easy checkout and payment",
      subtext: `Guests can choose their preferred payment method and check out in a few clicks. Groups can order and split their check from their own device. Or they can check out even more quickly by creating an account that securely stores payment information.`,
      imageName: "products-online-ordering-product-3-new.png",
      cta: {
        title: "Learn more",
        target: "/enterprise/mobile-and-online-ordering/demo",
      },
    },
  ],
};

export const highlightWithImage = {
  title: "Keep your customers in the know",
  subtext: `Enhance the customer experience by displaying an order status board and/or sending your customers text/email alerts to let them know the status of their orders.`,
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/mobile-and-online-ordering/demo",
  },
  imageName: "products-online-ordering-product-4.png",
};

export const headingsWithIcons = [
  {
    iconKey: "products-retail",
    title: "Omnichannel <br /> solution",
    subtext:
      "Full omnichanel integration enables customers to order the way they want.",
  },
  {
    iconKey: "discount",
    title: "Robust discount promotions engine",
    subtext:
      "Easily implement various discounts or promotions with our enterprise-level discount engine.",
  },
  {
    iconKey: "online-reservation",
    title: "Multi-vendor <br /> support",
    subtext:
      "Provide customers with the ability to view menus from multiple vendors on a single website.",
  },
  {
    iconKey: "kitchen-displays",
    title: "Sync real-time with kitchen display system",
    subtext:
      "Orders submitted get synced real-time just like an order submitted through a traditional POS.",
  },
];

export const highlightWithImageDark = {
  title: "Same-day order ahead",
  subtext: `Customers are often looking for the quickest, most convenient way to grab a drink or bite to eat and there is no easier way than ordering ahead. With a few simple clicks, customers can convenietly submit an order for pick up later that day.`,
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/mobile-and-online-ordering/demo",
  },
  imageName: "products-online-ordering-product-5.png",
};

export const partners = {
  title:
    "Increase average order size by as much as 20 – 30% like these enterprise businesses",
  desktopImageName: "appetize-online-ordering-partners-desktop.png",
  mobileImageName: "appetize-online-ordering-partners-mobile.png",
};

export const productOverview2 = {
  subSections: [
    {
      title: "SpotOn Scan + Go",
      subtext: `Enable guests to seamlessly check out using their own smartphone camera. This limited interaction solution alleviates congested checkout lines, while providing customers convenience, safety, and an efficient ordering experience. All without any traditional POS hardware cost.`,
      imageName: "products-online-ordering-product-6.png",
    },
  ],
};

export const partners2 = {
  title: "Integrated with partners you already know and trust",
  desktopImageName: "appetize-online-ordering-partners-2-desktop.png",
  mobileImageName: "appetize-online-ordering-partners-2-mobile.png",
};

export const productsWithLinks = {
  title: "Built on a true omnichannel ecosystem",
  products: [
    {
      title: "Kiosks",
      description:
        "Give your guests direct control over their ordering experience as you speed up order fulfillment and improve your bottom line.",
      linkTo: "/enterprise/kiosks",
      imageName: "products-kiosk-2.png",
    },
    {
      title: "Handhelds",
      description:
        "Take orders and process payments courtside, tableside, or while guests are still in line using on-the-go point of sale solutions.",
      linkTo: "/enterprise/handhelds",
      imageName: "products-handheld.png",
    },
    {
      title: "Kitchen Displays",
      description:
        "Instantly transmit all POS orders to a centralized device for preparation, even if your internet connection goes down.",
      linkTo: "/enterprise/kitchen-displays",
      imageName: "products-kitchen-display.png",
    },
  ],
};

export const footer = {
  title: "Book a consultation",
  subtext:
    "See for yourself how our enterprise cloud POS and management platform can transform the guest experience, provide valuable business insights, and deliver ROI to your enterprise.",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/mobile-and-online-ordering/demo",
  },
};
