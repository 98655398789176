import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  hero,
  heroImage,
  secondaryHeroTwoCols,
  productOverview,
  highlightWithImage,
  headingsWithIcons,
  highlightWithImageDark,
  productOverview2,
  partners2,
  productsWithLinks,
  footer,
} from "../../../data/appetize/mobile-and-online-ordering";
import { enterpriseSupport } from "../../../data/spoton-contacts";

const SimpleHero = loadable(() =>
  import("../../../components-v2/Sections/Hero/SimpleHero")
);
const LargeImageFullWidth = loadable(() =>
  import("../../../components-v2/Sections/LargeImageFullWidth")
);
const SecondaryHeroTitleTwoCols = loadable(() =>
  import("../../../components-v2/Sections/Hero/SecondaryHeroTitleTwoCols")
);
const ProductOverview = loadable(() =>
  import("../../../components-v2/Sections/ProductOverview")
);
const HighlightWithImageAndOffsetText = loadable(() =>
  import("../../../components-v2/Sections/HighlightWithImageAndOffsetText")
);
const FeaturesWithIcons = loadable(() =>
  import("../../../components-v2/Sections/FeaturesWithIcons")
);
const PartnerLogosAppetize = loadable(() =>
  import("../../../components-v2/Sections/PartnerLogos/partner-logos-appetize")
);
const ProductsWithLinksPlainBackground = loadable(() =>
  import("../../../components-v2/Sections/ProductsWithLinksPlainBackground")
);
const FooterHero = loadable(() =>
  import("../../../components-v2/Sections/Hero/FooterHero")
);

const BackOfHouseManagement = () => {
  return (
    <Layout className="non-fixed-width" {...enterpriseSupport}>
      <SimpleHero className="mt-32 lg:mt-40" {...hero} />
      <SEO
        loadChat={false}
        title="Mobile and Online Ordering POS System | SpotOn"
        description={hero.title}
        cannonical="https://www.spoton.com/enterprise/mobile-and-online-ordering/"
      />
      <LargeImageFullWidth className="mt-16 lg:mt-20" {...heroImage} />

      <SecondaryHeroTitleTwoCols
        className="mt-8 lg:mt-0"
        {...secondaryHeroTwoCols}
      />

      <ProductOverview
        className="mt-20 lg:mt-36"
        {...productOverview}
        noMainTitle
        offsetText
        flipItems
      />

      <HighlightWithImageAndOffsetText
        className="mt-20 lg:mt-36"
        {...highlightWithImage}
        flipItems
      />

      <FeaturesWithIcons className="mt-20 lg:mt-36" items={headingsWithIcons} />

      <HighlightWithImageAndOffsetText
        className=""
        {...highlightWithImageDark}
        dark
      />

      {/* <PartnerLogosAppetize className="mt-20 lg:mt-36" {...partners} /> */}

      <ProductOverview
        className="mt-20 lg:mt-36"
        {...productOverview2}
        noMainTitle
        flipItems
      />

      <PartnerLogosAppetize className="mt-20 lg:mt-36" {...partners2} />

      <ProductsWithLinksPlainBackground
        className="mt-20 lg:mt-36"
        {...productsWithLinks}
      />

      <FooterHero className="my-20 lg:my-36" {...footer} />

      <div style={{ marginTop: 200 }} />
    </Layout>
  );
};

export default BackOfHouseManagement;
